import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {environment} from "../../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {TimesheetDto} from "../models/timesheet.model";
import {PunchingDto, PunchResponseDto} from "../models/punching.model";

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  apiUrl = `${environment.myBackend}/pms/timesheet/timesheet`;

  constructor(private http: HttpClient) {

  }

  addTimesheet(value: TimesheetDto): Observable<TimesheetDto> {
    return this.http.put<TimesheetDto>(this.apiUrl, value);
  }

  getTimesheet(filters: any): Observable<TimesheetDto[]> {
    let qs = ""
    if (filters && filters.sort) {
      qs += "sort=" + filters.sort[0] + "&sortOrder=" + filters.sort[1] + "&"
    }

    if (filters && filters.startDate) {
      qs += "startDate=" + filters.startDate + "&";
    }
    if (filters && filters.endDate) {
      qs += "endDate=" + filters.endDate + "&";
    }

    if (filters && filters.allFilter) {
      qs += "allFilter=" + filters.allFilter + "&"
    }

    return this.http.get<TimesheetDto[]>(`${this.apiUrl}?${qs}`);

  }

  updateTimesheet(id: number, value: any): Observable<TimesheetDto> {
    return this.http.patch<TimesheetDto>(`${this.apiUrl}/${id}`, value)
  }

  deleteTimesheet(id: number): Observable<any> {
    return this.http.delete<TimesheetDto>(`${this.apiUrl}/${id}`);
  }

  loadTimesheetReport(startDate: string | undefined, endDate: string | undefined, firstLevel: string, secondLevel: string, thirdLevel: string, logKey: string, selectedClient: number[] | undefined, selectedSubContract: number[] | undefined): Observable<any[]> {
    let qs = "?firstLevel=" + firstLevel + "&secondLevel=" + secondLevel + "&thirdLevel=" + thirdLevel + "&logKey=" + logKey

    if (startDate) {
      qs += "&startDate=" + startDate + "&endDate=" + endDate
    }
    if (selectedClient) {
      selectedClient.forEach(v => {
        qs += "&selectedClient=" + v
      })
    }

    if (selectedSubContract) {
      selectedSubContract.forEach(v => {
        qs += "&selectedSubContract=" + v
      })
    }


    return this.http.get<any[]>(`${this.apiUrl}/timesheetReport${qs}`);
  }

  loadPunching(startDate: string, endDate: string): Observable<PunchResponseDto> {
    return this.http.get<PunchResponseDto>(`${this.apiUrl}/getPunchingReport?startDate=${startDate}&endDate=${endDate}`);
  }
}
