import {createReducer, on} from '@ngrx/store';
import * as TimesheetReportActions from '../actions/timesheetReport.actions';
import {TimesheetDto} from "../models/timesheet.model";

export interface TimesheetReportState {
  planLogTimeReport: any[];
  attendanceLogTimeReport: any[];
  billableLogTimeReport: any[];
  nonBillableLogTimeReport: any[];
  overTimeWithoutPayLogTimeReport: any[];
  overTimePayLogTimeReport: any[];
  invoicingReport: any[];
  error: any;
}

export const initialState: TimesheetReportState = {
  planLogTimeReport: [],
  attendanceLogTimeReport: [],
  billableLogTimeReport: [],
  nonBillableLogTimeReport: [],
  overTimeWithoutPayLogTimeReport: [],
  overTimePayLogTimeReport: [],
  invoicingReport: [],
  error: null,
};

export const timesheetReportReducer = createReducer(
  initialState,

  on(TimesheetReportActions.loadPlanLogTimeReportSuccess, (state, {timesheetReport}) => ({
    ...state,
    planLogTimeReport: [...timesheetReport]
  })),
  on(TimesheetReportActions.loadAttendanceLogTimeReportSuccess, (state, {timesheetReport}) => ({
    ...state,
    attendanceLogTimeReport: [...timesheetReport]
  })),
  on(TimesheetReportActions.loadBillableLogTimeReportSuccess, (state, {timesheetReport}) => ({
    ...state,
    billableLogTimeReport: [...timesheetReport]
  })),
  on(TimesheetReportActions.loadNonBillableLogTimeReportSuccess, (state, {timesheetReport}) => ({
    ...state,
    nonBillableLogTimeReport: [...timesheetReport]
  })),
  on(TimesheetReportActions.loadOverTimePayLogTimeReportSuccess, (state, {timesheetReport}) => ({
    ...state,
    overTimeWithoutPayLogTimeReport: [...timesheetReport]
  })),
  on(TimesheetReportActions.loadOverTimeWithoutPayLogTimeReportSuccess, (state, {timesheetReport}) => ({
    ...state,
    overTimePayLogTimeReport: [...timesheetReport]
  })),
  on(TimesheetReportActions.loadInvoicingHoursReportSuccess, (state, {timesheetReport}) => ({
    ...state,
    invoicingReport: [...timesheetReport]
  })),
);
