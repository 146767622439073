import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TimesheetService} from '../services/timesheet.service';
import * as TimesheetReportActions from '../actions/timesheetReport.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class TimesheetReportEffects {
  constructor(private actions$: Actions, private timesheetService: TimesheetService) {}

  loadPlanReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetReportActions.loadPlanLogTimeReport),
      mergeMap(action =>
        this.timesheetService.loadTimesheetReport(action.startDate, action.endDate, action.firstLevel, action.secondLevel, action.thirdLevel, action.logKey,action.selectedClient,action.selectedSubContract).pipe(
          map(timesheetReport => TimesheetReportActions.loadPlanLogTimeReportSuccess({ timesheetReport })),
          catchError(error => of(TimesheetReportActions.loadTimesheetReportFailure({ error })))
        )
      )
    )
  );
  loadAttendenceReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetReportActions.loadAttendanceLogTimeReport),
      mergeMap(action =>
        this.timesheetService.loadTimesheetReport(action.startDate, action.endDate, action.firstLevel, action.secondLevel, action.thirdLevel, action.logKey,action.selectedClient,action.selectedSubContract).pipe(
          map(timesheetReport => TimesheetReportActions.loadAttendanceLogTimeReportSuccess({ timesheetReport })),
          catchError(error => of(TimesheetReportActions.loadTimesheetReportFailure({ error })))
        )
      )
    )
  );
  loadBillableReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetReportActions.loadBillableLogTimeReport),
      mergeMap(action =>
        this.timesheetService.loadTimesheetReport(action.startDate, action.endDate, action.firstLevel, action.secondLevel, action.thirdLevel, action.logKey,action.selectedClient,action.selectedSubContract).pipe(
          map(timesheetReport => TimesheetReportActions.loadBillableLogTimeReportSuccess({ timesheetReport })),
          catchError(error => of(TimesheetReportActions.loadTimesheetReportFailure({ error })))
        )
      )
    )
  );
  loadNonBillableReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetReportActions.loadNonBillableLogTimeReport),
      mergeMap(action =>
        this.timesheetService.loadTimesheetReport(action.startDate, action.endDate, action.firstLevel, action.secondLevel, action.thirdLevel, action.logKey,action.selectedClient,action.selectedSubContract).pipe(
          map(timesheetReport => TimesheetReportActions.loadNonBillableLogTimeReportSuccess({ timesheetReport })),
          catchError(error => of(TimesheetReportActions.loadTimesheetReportFailure({ error })))
        )
      )
    )
  );
  loadOverTimeReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetReportActions.loadOverTimePayLogTimeReport),
      mergeMap(action =>
        this.timesheetService.loadTimesheetReport(action.startDate, action.endDate, action.firstLevel, action.secondLevel, action.thirdLevel, action.logKey,action.selectedClient,action.selectedSubContract).pipe(
          map(timesheetReport => TimesheetReportActions.loadOverTimePayLogTimeReportSuccess({ timesheetReport })),
          catchError(error => of(TimesheetReportActions.loadTimesheetReportFailure({ error })))
        )
      )
    )
  );
  loadOverTimeWithoutReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetReportActions.loadOverTimeWithoutPayLogTimeReport),
      mergeMap(action =>
        this.timesheetService.loadTimesheetReport(action.startDate, action.endDate, action.firstLevel, action.secondLevel, action.thirdLevel, action.logKey,action.selectedClient,action.selectedSubContract).pipe(
          map(timesheetReport => TimesheetReportActions.loadOverTimeWithoutPayLogTimeReportSuccess({ timesheetReport })),
          catchError(error => of(TimesheetReportActions.loadTimesheetReportFailure({ error })))
        )
      )
    )
  );
  loadInvoicingReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TimesheetReportActions.loadInvoicingHoursReport),
      mergeMap(action =>
        this.timesheetService.loadTimesheetReport(action.startDate, action.endDate, action.firstLevel, action.secondLevel, action.thirdLevel, action.logKey,action.selectedClient,action.selectedSubContract).pipe(
          map(timesheetReport => TimesheetReportActions.loadInvoicingHoursReportSuccess({ timesheetReport })),
          catchError(error => of(TimesheetReportActions.loadTimesheetReportFailure({ error })))
        )
      )
    )
  );
}
